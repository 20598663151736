rodijco.controller 'ProductsController', [
  '$scope'
  '$routeParams'
  '$location'
  'httpService'
  (scope, routeParams, $location, httpService) ->

    scope.selectedGroup = if routeParams.group then routeParams.group else 0
    scope.products = {}
    scope.savedProducts = {}

    scope.currentPage = if isNaN(routeParams.page) then 0 else routeParams.page
    scope.count = 0
    scope.limit = 10
    scope.totalPages = 0
    scope.showPages = 1
    scope.showHellip = false

    setTimeout (->
      httpService.get 'products/groups', (response) ->
        scope.productGroups = response.data

        firstGroup = scope.productGroups?[0]?._id
        if firstGroup and not scope.selectedGroup
          $location.path('/products/' + firstGroup)

      if scope.selectedGroup
        httpService.get "products?group=#{scope.selectedGroup}&page=#{scope.currentPage}", (response) ->
          if response.success
            scope.products = response.data?.products
            scope.savedProducts = response.data?.products
            scope.count = response.data?.count

            rounded = Math.ceil(scope.count / scope.limit)
            rounded = if rounded > 0 then rounded - 1 else rounded
            scope.totalPages = [0..(rounded)]

            scope.showHellip = if rounded > (rounded - scope.showPages) then true else false
    ), 5

    scope.$watch 'search', ->
      unless scope.search and scope.search.length > 1
        scope.products = scope.savedProducts
        return

      httpService.get "products/search/#{scope.search}?group=#{scope.selectedGroup}", (response) ->
        scope.products = response.data?.results if response.success

    scope.removeGroup = (group) ->
      httpService.delete "products/groups/#{group}", (response) ->
        if response.success
          Materialize.toast 'Product groep verwijderd', DIALOG_DURATION
          $location.path '/products'
        else
          console.error response

    scope.removeProduct = (product) ->
      httpService.delete "products/#{product}", (response) ->
        if response.success
          location.reload()
        else
          console.error response

]
